























import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '../../../lib/util'
import AbpBase from '../../../lib/abpbase'
import PackageType from '../../../store/entities/packagetype'

@Component
export default class EditPackageType extends AbpBase {
    @Prop({type: Boolean, default: false}) value: boolean;
    @Prop({type: Boolean, default: false}) public toEdit: boolean;
    packageType: PackageType = new PackageType();

    created() {
    }

    save() {
        (this.$refs.packageTypeForm as any).validate(async (valid: boolean) => {
            if (valid) {
                await this.$store.dispatch({
                    type: this.toEdit ? 'packagetype/update' : 'packagetype/create',
                    data: this.packageType
                });
                (this.$refs.packageTypeForm as any).resetFields();
                this.$emit('save-success');
                this.$emit('input', false);
            }
        })
    }

    cancel() {
        (this.$refs.packageTypeForm as any).resetFields();
        this.$emit('input', false);
    }

    visibleChange(value: boolean) {
        if (!value) {
            this.$emit('input', value);
        } else {
            if (this.toEdit) {
                this.packageType = Util.extend(true, {}, this.$store.state.packagetype.editPackageType);
            }
            else {
                this.packageType = new PackageType();
            }

        }
    }

    packageTypeRule = {
        name: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Name')), trigger: 'blur'}],
        code: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Code')), trigger: 'blur'}]
    }
}
